import React from "react"
import _ from "lodash"

import SEO from "../components/seo";
import Layout from "../components/layout"
import Header from "../components/Header"
import Product from "../components/products/Product"

export default ({ pageContext }) => {
  return (
    <Layout>
      <Header />
      <div className="grid-x grid-margin-x">
        <Product Item={pageContext} />
      </div>
    </Layout>
  )
}
