import React, { Fragment, useEffect, useState, useContext } from "react"
import { CartContext } from "../CartContext"
import _ from "lodash"
import { graphqlOperation, API } from "aws-amplify"
import { createProductNotification } from "../../graphql/cart"
import { fetchProductsById } from "../../utils/public-api"
import TextInput from "../TextInput"
import Button from "../Button"
import config from "../../../config"

const ENV_CONFIG = config.get()

export default ({ Item }) => {
  const {
    NamePlugtrays,
    BotanicalName,
    CommonName,
    MVGPath,
    Height,
    Hardiness,
    FlowerColor,
    Characteristics,
    Uses,
    Id,
    Images,
    TagsPlugtrays,
  } = Item

  const { Cart } = useContext(CartContext)
  const [product, setProduct] = useState(undefined)
  const [quantity, setQuantity] = useState(1)
  const [submitState, setSubmitState] = useState({
    submitting: false,
    message: undefined,
  })

  useEffect(() => {
    const f = async () => {
      const result = await fetchProductsById([Id])
      setProduct(
        _.pick(result.Products[0], ["PricePlugtrays", "InStockPlugtrays"])
      )
    }
    f()
  }, [])

  let image = _.get(Images, 0)
  let renderedStock

  const buildImage = (img, index) => {
    return (
      <div key={index}>
        <img
          src={`${ENV_CONFIG.productImageCDNPath}${Id}/${index}-medium.jpg`}
          alt=""
          itemProp="image"
        />
      </div>
    )
  }

  const cartQuantity = _.get(Cart, ["CartProducts", Id]) || 0

  const addToCart = async () => {
    try {
      setSubmitState({ submitting: true })
      await Cart.setCartProductQuantity(Id, cartQuantity + parseInt(quantity))
      console.log(`added ${quantity} to cart!`)
      setSubmitState({ submitting: false })
    } catch (err) {
      setSubmitState({ submitting: false, message: err }) //TODO: revisit this error, make sure it renders
    }
  }

  const [notifyVisible, setNotifyVisible] = useState(false)
  const [notifyThankYouMsgVisible, setNotifyThankYouMsgVisible] = useState(
    false
  )
  const [submitError, setSubmitError] = useState(undefined)
  const [validationVisible, setValidationVisible] = useState(false)

  let notifyInput
  const [Email, EmailRendered, SetEmail] = TextInput({
    validationVisible,
    labelText: "Email address",
    type: "email",
    required: true,
  })

  if (notifyVisible) {
    const handleSubmit = async event => {
      event.preventDefault()
      try {
        const result = await API.graphql(
          graphqlOperation(createProductNotification, {
            ProductId: Id,
            Email: Email,
          })
        )
        setNotifyVisible(false)
        setNotifyThankYouMsgVisible(true)
        setSubmitError(undefined)
      } catch (err) {
        setSubmitError(
          _.map(_.get(err, "errors"), ({ message }) => `${message}`).join(",")
        )
        console.error({ err }, "got err!")
      }
    }

    notifyInput = (
      <form onSubmit={handleSubmit} className="grid-x product--notify-box">
        <div className="cell small-8 medium-6">{EmailRendered}</div>
        <div className="cell small-4 medium-4">
          <Button type="submit" text="Notify me!" />
        </div>
        <br />
        {submitError}
      </form>
    )
  }

  if (_.get(product, "InStockPlugtrays") > 0) {
    // Render cart button
    renderedStock = (
      <div className="product--quantity">
        <input
          type="number"
          defaultValue="1"
          onChange={e => setQuantity(e.target.value)}
        />
        <Button
          style={submitState.submitting ? "loading" : undefined}
          isDisabled={submitState.submitting}
          onClickHandler={() => addToCart()}
          text="Add to cart"
        />
      </div>
    )
  } else {
    renderedStock = (
      <div>
        {!notifyVisible && !notifyThankYouMsgVisible && (
          <Button
            style="hollow"
            onClickHandler={() => setNotifyVisible(!notifyVisible)}
            text="
          Notify me when this plant is back in stock"
          />
        )}
        {notifyInput}
        {notifyThankYouMsgVisible && (
          <p className="callout primary">
            Thank you! You'll receive an email when we have this item back in
            stock.
          </p>
        )}
      </div>
    )
  }

  return (
    <div
      itemScope
      itemType="http://schema.org/Product"
      className="grid-x grid-margin-x"
    >
      {image && (
        <div className="cell small-12 medium-6 large-4 product--images">
          <div className="grid-x grid-margin-y">
            {Images.map((img, index) => buildImage(img, index))}
          </div>
        </div>
      )}
      <div className="cell small-12 medium-6 large-8 product--details">
        <h1 className="product--header" itemProp="name">
          <span className="h4">{BotanicalName}</span>
          {CommonName}
        </h1>
        <p className="product--price h4" itemProp="price">
          ${_.get(product, "PricePlugtrays")}{" "}
          <span>per tray of 128 plants</span>
        </p>
        {renderedStock}
        <h3>About this plant</h3>
        <dl itemProp="description">
          {Height && (
            <>
              <dt className="header__small-caps">Height</dt>
              <dd>{Height}</dd>
            </>
          )}
          {Hardiness && (
            <>
              <dt className="header__small-caps">Hardiness</dt>
              <dd>{Hardiness}</dd>
            </>
          )}
          {FlowerColor && (
            <>
              <dt className="header__small-caps">Flower Color</dt>
              <dd>{FlowerColor}</dd>
            </>
          )}
          {Characteristics && (
            <>
              <dt className="header__small-caps">Characteristics</dt>
              <dd>{Characteristics}</dd>
            </>
          )}
          {Uses && (
            <>
              <dt className="header__small-caps">Uses</dt>
              <dd>{Uses}</dd>
            </>
          )}
        </dl>
        <p>
          <a href={`https://mountainvalleygrowers.com${MVGPath}`}>
            Read more about this plant on Mountain Valley Growers »
          </a>
        </p>
      </div>
    </div>
  )
}
